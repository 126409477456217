import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Main } from 'react-router-dom';
import AppRoutes from './Routes';
import { Routes, Route } from 'react-router-dom';
import { createSignalRContext } from "react-signalr/signalr";

const SignalRContext = createSignalRContext();

function App() {
  const token = localStorage.getItem('token');

  return (
    <div className="App">
      <Main>
        {/* <SignalRContext.Provider
          connectEnabled={!!token}
          accessTokenFactory={() => token}
          dependencies={[token]} //remove previous connection and create a new connection if changed
          url={"http://20.198.5.41:9001/chathub"}
        > */}
          <AppRoutes />
        {/* </SignalRContext.Provider> */}
      </Main>
    </div>
  );
}

export default App;
