import React from 'react';
import '../../Styles/PromotionBanner.css';

const PromotionBanner = () => {
  return (
    <div>
      <div className="chat-right-index">
        <ul className="nav nav-pills nav-fill gap-2 p-1 small rounded-5 shadow-sm" id="pillNav2" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active rounded-5"
              id="home-tab2"
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-selected="true"
            >
              Home
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link rounded-5"
              id="profile-tab2"
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-selected="false"
            >
              Profile
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link rounded-5"
              id="contact-tab2"
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-selected="false"
            >
              Contact
            </button>
          </li>
        </ul>
      </div>
      <div className="list-sidebar">
        <h1>
          Still
          <br />
          need
          <br />
          hosting?
        </h1>
        <div className="get-started">Get Started for</div>
        <div className="rup">$ 2.95</div>
        <p>/month</p>
        <div className="buy-now">Buy Now</div>
      </div>
    </div>
  );
};

export default PromotionBanner;
