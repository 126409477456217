import React, { useEffect, useState } from "react";
import '../Styles/navbar.css';
import { useNavigate } from 'react-router-dom';
import firsthouse from '../Assets/firsthouse.svg';
import location from '../Assets/location1.svg';
import downarrow from '../Assets/downarrow.svg';
import messages from '../Assets/messages.svg';
import { RiAdvertisementLine } from "react-icons/ri";
import { FaListCheck } from "react-icons/fa6";
import profile from '../Assets/FH_profile.svg';
import listings from '../Assets/listings.svg';
import crown from '../Assets/Crown Star.svg';
import services from '../Assets/fh_services.svg';
import logout from '../Assets/logout.svg';
import heart from '../Assets/heart.svg';
import axios from "axios";
import { BASE_URL } from "../Services/BaseUrl";

const Navbar = () => {
  const navigate = useNavigate();
  const [isSmallWindow, setIsSmallWindow] = useState(window.innerWidth <= 768); // Initialize based on current window width
  const [locations, setLocations] = useState([]);
  const [profileImage, setProfileImage] = useState(""); // State for profile image
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const [locationName, setLocationName] = useState("");
  const [userName, setUserName] = useState(""); // State for username
  const isAuthenticated = userId && token;
  // const profileImage= localStorage.getItem('profileImage');
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.clear();
    navigate('/', { replace: true });
    
  };

  const encodeUri = (val) => {
    if (val === null) {
      return profile;
    }
    if (val && typeof val === 'string' && val.startsWith("http")) {
      return val; // Return the URL as is if it's already a full URL
    }
    return `${BASE_URL}/Upload/${encodeURI(val)}`;
  };
  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Users/${userId}`,          {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }); // Fetch user details with userId
      setProfileImage(encodeUri(response.data.profileImage) || profile); // Set the initial profile image
      setUserName(response.data.username)


      console.log(response.data, "profile details");
    } catch (error) {
      console.error('Error fetching profile', error);
    }
  };
  const fetchData = async (apiUrl, setData) => {
    try {
      const response = await axios.get(apiUrl,          {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(`${BASE_URL}/Locations`, setLocations);
    fetchProfile();
  }, []);
  useEffect(() => {
    fetchData(`${BASE_URL}/Locations`, setLocations);
    fetchProfile();
  }, []);
  useEffect(() => {
    // Update profile image and username if local storage changes
    const handleStorageChange = () => {
      const newImage = localStorage.getItem("profileImage");
      const newUserName = localStorage.getItem("username");
      console.log(newImage,newUserName,'window event');
      // Update state only if the values are not null or empty
      if (newImage) setProfileImage(encodeUri(newImage));
      if (newUserName) setUserName(newUserName);
    };
    // Listen for storage changes
    window.addEventListener("storage", handleStorageChange);
    // Cleanup listener on component unmount
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallWindow(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchLocationName = async () => {
      if (latitude && longitude) {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          setLocationName(response.data.address.suburb || "Unknown Location"); // Set the location name
          console.log(response.data, "location name");
        } catch (error) {
          console.error("Error fetching location name:", error);
        }
      }
    };

    fetchLocationName();
  }, [latitude, longitude]);


  return (
    <nav style={{ position: "relative", zIndex: "2" }}>
      <div className='navbar-content1'>
        <input type="checkbox" id="check" />
        <label htmlFor="check" className="checkbtn">
          <i className="fas fa-bars"></i>
        </label>
        <div className='nav-right-content1'>
          <div>
            <img src={firsthouse} loading="lazy" alt='' onClick={() => navigate('/home')} style={{ cursor: "pointer" }} />
          </div>
          <div className='nav-right'>
            <img src={location} loading="lazy" alt='' style={{ cursor: "pointer" }} />
            <div>
              {/* <p>{locationName ? locationName : "Fetching location..."}</p> */}
              <p>{locationName ? locationName : "Fetching location..."}</p>
            </div>

          </div>
        </div>
        <div className='nav-left1'>
          <button type="button" className="btn btn-outline-primary" onClick={() => navigate('/post-ad-1')} style={{ border: "1px solid #00DBBF", color: "#00DBBF", backgroundColor: "transparent" }}>
            {isSmallWindow ? <RiAdvertisementLine className="post-ad-icon" /> : <p>Post Ad</p>}
          </button>
          <button type="button" className="btn btn-primary" onClick={() => navigate('/list-property')}>
            {isSmallWindow ? <FaListCheck className="list-prop-icon" /> : <p>List Your Property</p>}
          </button>
          {!isAuthenticated && <button className="btn btn-primary" onClick={() => navigate('/login')}>
            Login
          </button>}
          {isAuthenticated &&
            <>
              <div className='dropdown'>
                <div className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div className='navProfile'>
                    <img loading="lazy" src={profileImage} alt="Profile" />
                  </div>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div className='dropdown-profile'>
                      <img src={profileImage} loading="lazy" alt="Profile" />
                      <p>{userName}</p>
                    </div>
                    <button className='btn edit-profile btn-primary' onClick={() => handleNavigation(`/edit-profile`)}>
                      View and Edit Profile
                    </button>
                    <div className='profile-items'>
                      <button className="dropdown-item" onClick={() => handleNavigation('/MyListing1')}>
                        <img src={listings} loading="lazy" alt="listings" /> My Listings
                      </button>
                      <button className="dropdown-item" onClick={() => handleNavigation('/favourites')}>
                        <img src={heart} loading="lazy" alt="favourites" /> Favourites
                      </button>
                      <button className="dropdown-item" onClick={() => handleNavigation('/Buypackages')}>
                        <img src={crown} loading="lazy" alt="crown" /> Buy Packages and More
                      </button>
                      <button className="dropdown-item" onClick={() => handleNavigation('/my-services')}>
                        <img src={services} loading="lazy" alt="services" /> Services
                      </button>
                      <button className="dropdown-item" onClick={handleLogout}>
                        <img src={logout} loading="lazy" alt="logout" /> Logout
                      </button>
                    </div>


                  </div>
                </div>
              </div>
            </>}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
