import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../Styles/VillaDetails.css";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import properties_icon from "../../Assets/properties_icon.svg";
import house from "../../Assets/vd_houseimg.svg";
import picimg from "../../Assets/Gallery.svg";
// import MAP_API from '../../Services/GmapAPI'
import { MAP_API } from '../../Services/GmapAPI'
import { type } from "@testing-library/user-event/dist/type";
import { BsDisplay } from "react-icons/bs";
import { BASE_URL } from "../../Services/BaseUrl";
import { CurrencyRupee } from "@mui/icons-material";
// import { AdvancedMarkerElement } from '@react-google-maps/marker';

const HomeDetails = () => {
  const { villaid } = useParams();
  const [villaDetails, setVillaDetails] = useState(null);
  const [chat,setchat]=useState(false)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_API, // Corrected to use a string
  });
  const token =localStorage.getItem('token');

  const encodeUri = (val) => {
    // Check if val is not null or undefined
    if (val && typeof val === "string") {
      // Check if the value starts with 'http'
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }

    // Handle the case where val is null or undefined
    console.warn(
      "Provided value is null or undefined, returning empty string."
    );
    return ""; // Or handle it as you see fit
  };

  useEffect(() => {
    fetch(`${BASE_URL}/Properties/${villaid}`,          {
      headers: {
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVillaDetails(data.vm);

      })
      .catch((error) => console.error("Error fetching villa details:", error));
  }, [villaid]);
  if (!villaDetails) {
    return <div>Loading...</div>;
  }

  const decodeImgeString = (str) => {
    if (str) {
      // Split the string by commas to get an array of image paths
      const imageArray = str.split(",");
      // Return the array of encoded image URLs
      return imageArray.map((image) => encodeUri(image.trim()));
    }
    return []; // Return an empty array if the string is null or empty
  };
  const mapCenter = {
    lat: Number(villaDetails.latitude),
    lng: Number(villaDetails.longitude),
  };

  const mapContainerStyle = {
    height: '300px', // Set the height as needed
    width: '100%',
    marginBottom: '16px',
  };
  const customMapStyle = [
    {
      featureType: 'all',
      elementType: 'labels.text',
      stylers: [{ color: '#878787' }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [{ color: '#f9f5ed' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#c9c9c9' }],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [{ color: '#aee0f4' }],
    },
  ];
  console.log(villaDetails, "jijji");
  console.log(mapCenter, "map center");
  console.log(MAP_API)
  return (
    <div className="villadetails-container">
      {/* {villaDetails.map((villadetails, index) => ( */}
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            padding: "24px 0 0 0",
          }}
        >
          <div
            className="viewdetails-content"
            style={{ borderRadius: "30px", overflow: "hidden" }}
          >
            <div className="house-container">
              <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                  {decodeImgeString(villaDetails.images).map((image, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <img
                        src={image}
                        alt={`Villa Image ${index + 1}`}
                        className="ml2-house-image"
                      />
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="prev"
                  style={{ width: "5%" }}
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="next"
                  style={{ width: "5%" }}
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
                <div className="vd-count-pics">
                  <img src={picimg} alt="" />
                  <p>{villaDetails.images.split(",").length} Photos</p>
                </div>
              </div>
            </div>
            <div className="container">
           
                <div className="float_section">
                  <div class="features d-flex justify-content-between align-items-center my-bg-light">
                    <div class="feature-item d-flex align-items-center flex-column">
                      {/* <img src={properties_icon} alt="" /> */}
                      <p className="mb-0 feature-title">Property Type</p>
                      <h6>{villaDetails.propertyType}</h6>
                    </div>
                    <div class="feature-item d-flex align-items-center flex-column">
                      {/* <img src={area_icon} alt="" /> */}
                      <p class="mb-0 feature-title">Area</p>
                      <h6>{villaDetails.area}</h6>
                    </div>
                    <div class="feature-item d-flex align-items-center flex-column">
                      {/* <img src={bed} alt="" /> */}
                      <p class="mb-0 feature-title">No. of Bedrooms</p>
                      <h6>4 Beds</h6>
                    </div>
                    <div class="feature-item d-flex align-items-center flex-column">
                      {/* <img src={wheel} alt="" /> */}
                      <p class="mb-0 feature-title">Parkings</p>
                      <h6>{villaDetails.parking} Parkings</h6>
                    </div>
                    <div class="feature-item d-flex align-items-center flex-column">
                      {/* <img src={bath} alt="" /> */}
                      <p class="mb-0 feature-title">No. of Bathrooms</p>
                      <h6>{villaDetails.bathrooms} Baths</h6>
                    </div>
                  </div>
                </div>
           
            </div>
          </div>
          <div className="col-lg-3 col-md-6 px-2">
            {/* card */}
            <div class="property-card_sec">
              {/* <div className="layer"></div> */}
              <div className="property-card-text">
                <h4>{villaDetails.propertyName}</h4>
                <p className="text-light">
                  {villaDetails.noOfRooms}BHK - {villaDetails.area}
                </p>
                <h3>
                  <span className="text-light">Price </span>₹{" "}
                  {villaDetails.propertyPrice}
                </h3>
              </div>
              <div class=" text-center d-flex justify-content-center align-items-center flex-column py-3 px-3">
                <div style={mapContainerStyle}>
                  {isLoaded ? (
                    <GoogleMap
                      zoom={15}
                      center={mapCenter}
                      mapContainerStyle={mapContainerStyle}
                      options={{ styles: customMapStyle }} // Applying custom styles
                    >
                      {mapCenter?.lat && mapCenter?.lng && (
                        <Marker
                          position={{
                            lat: mapCenter.lat,
                            lng: mapCenter.lng
                          }}
                          title="Location"
                        />
                      )}
                    </GoogleMap>
                  ) : (
                    <p>Loading Map...</p>
                  )}
                </div>
{villaDetails.sellerImage && villaDetails.sellerImage &&(
                <div class="seller-image">
                  <img
                    src={encodeUri(villaDetails.sellerImage)}
                    loading="lazy"
                    alt="Owner Image"
                    class="me-2"
                  />
                  <div>
                    <p class="mb-0">Owner Name</p>
                    <h5 class="mb-0">{villaDetails.sellername}</h5>
                  </div>
                </div>)}
                <div class="owner-info_cta">
                  <div className="d-flex justify-content-around">
                    <button
                      class="btn btn-outline-light px-5"
                      onClick={() =>
                        setchat(true)
                      }
                    >
                      Chat
                    </button>
                   
                    <button class="btn btn-outline-light" onClick={() =>
      //  setTimeout
       setchat(true)
                    }>Quote Price</button>
                  </div>
              
                  <div>
                
                  {chat? <><br></br><p>Download the mobile app for chats,Quote price and contact</p></>:false}
                  </div>
                  <button class="mt-3 my-bg-primary w-100 chat-dots-btn owner-btn" onClick={() =>
                  setchat(true)
                  }>
                    <span>
                      <i class="bi bi-telephone-fill px-2"> </i>
                    </span>
                    Contact Owner
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-light my-bg-secondary-1">
            {/* <div className="col-md-9"> */}
              {/* <!-- Description Section --> */}
              <div class="description ">
                <h5 class="section-title">Title</h5>
                <p>{villaDetails.adTitle}</p>
              </div>
              <div class="description">
                <h5 class="section-title">Description</h5>
                <p>{villaDetails.description}</p>
              </div>
              {/* <!-- More Details Section --> */}
              <div class="more-details">
                <h5 class="section-title">More Details</h5>
                <div class="row">
                  <table class="details_table">
                    <thead>
                      <tr>
                        <th>Price Break Up </th>
                        <td>:<CurrencyRupee style={{ width: "20px" }}></CurrencyRupee>{villaDetails.propertyPrice}</td>
                      </tr>
                   { villaDetails.totalFloors &&  <tr>
                        <th class="info">Total Floors</th>
                        <td>: {villaDetails.totalFloors}</td>
                      </tr>}
                  {villaDetails.balconies &&    <tr>
                        <th class="info">Balconies</th>
                        <td>: {villaDetails.balconies}</td>
                      </tr>}
                {  villaDetails.carParking &&            <tr>
                        <th class="info">Car Parking</th>
                        <td>: {villaDetails.carParking}</td>
                      </tr>}
                  {villaDetails.carpetArea && <tr>
                        <th class="info">Carpet Area</th>
                        <td>: {villaDetails.carpetArea}</td>
                      </tr>}
                   { villaDetails.constructionStatus &&  <tr>
                        <th class="info">Construction Status</th>
                        <td>: {villaDetails.constructionStatus}</td>
                      </tr>}
                    {villaDetails.floorNo &&  <tr>
                        <th class="info">Floor No</th>
                        <td>: {villaDetails.floorNo}</td>
                      </tr>}
                  {villaDetails.noOfRooms &&  <tr>
                        <th class="info">No.Of Rooms</th>
                        <td>: {villaDetails.noOfRooms}</td>
                      </tr>}
                  {villaDetails.furnishedStatusName &&    <tr>
                        <th class="info">Furnished Status</th>
                        <td>: {villaDetails.furnishedStatusName}</td>
                      </tr>}
                {villaDetails.roadWidth && <tr>
                        <th class="info">Road Width</th>
                        <td>: {villaDetails.roadWidth}</td>
                      </tr>}
                    {villaDetails.address &&  <tr>
                        <th class="info">Address</th>
                        <td>: {villaDetails.address}</td>
                      </tr>}
                 {villaDetails.furnishedStatusName &&     <tr>
                        <th class="info">Furnishing</th>
                        <td>: {villaDetails.furnishedStatusName}</td>
                      </tr>}
              {villaDetails.parking &&   <tr>
                        <th class="info">Parking</th>
                        <td>: {villaDetails.parking}</td>
                      </tr>}
                    { villaDetails.facing &&  <tr>
                        <th class="info">Facing</th>
                        <td>: {villaDetails.facing}</td>
                      </tr>}
                 { villaDetails.locationName &&    <tr>
                        <th class="info">Location</th>
                        <td>: {villaDetails.locationName}</td>
                      </tr>}
                {  villaDetails.availableFrom &&     <tr>
                        <th className="info">Availabile From</th>
                        <td>
                          : {new Date(villaDetails.availableFrom).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })} - {new Date(villaDetails.availableFrom).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                        </td>

                      </tr>}
                   {villaDetails.possessionStatusName &&   <tr>
                        <th class="info">Possession Status</th>
                        <td>: {villaDetails.possessionStatusName}</td>
                      </tr>}
                    </thead>
                  </table>
                </div>
              </div>
              {/* <!-- Amenities Section --> */}
              <div class="amenities mb-5">
                <h5 class="section-title">Amenities</h5>
                <div class="row amenities-row more-details">
                  <div class="col-md-4">
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Power Back-Up
                    </p>
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Garden View
                    </p>
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Community Hall
                    </p>
                  </div>
                  <div class="col-md-4">
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Lift
                    </p>
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Badminton Court
                    </p>
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Gym
                    </p>
                  </div>
                  <div class="col-md-4">
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Lift
                    </p>
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Badminton Court
                    </p>
                    <p>
                      <span>
                        <img src={properties_icon} loading="lazy" alt="" />
                      </span>
                      Gym
                    </p>
                  </div>
                </div>
              </div>
            {/* </div> */}

        </div>
      </>
      {/* ))}  */}
    </div>
  );
};

export default HomeDetails;
