import React, { useState, useEffect } from "react";
import "../../Styles/ServiceForm.css";
import { useNavigate } from "react-router-dom";
import partyicon from "../../Assets/party-icon.svg";
import PaymentScreen from "./Paymentscreen";
import { FaRegImage } from "react-icons/fa6";
import { BASE_URL } from "../../Services/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RenderRazorpay from '../../Components/PayModule/PayModule'

const ServiceForm = () => {
  const navigate = useNavigate(); // Hook to navigate between routes
  const [serviceplans, setServiceplans] = useState([]);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const[uploadProgress ,setUploadProgress] = useState(0)
  const [Subcategories, setSubCategories] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [catid, setcatid] = useState(null);
  const [payScreen, setpayscreen] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [error, setError] = useState(null);
  const [freePlan,setFreePlan]=useState(false);
  const [freeAdd, setFreeAdd]=useState([])
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const [selectedOptions, setSelectedOptions] = useState([]); // State to track selected subcategories
  const userId = localStorage.getItem("userId"); // Get user ID from local storage
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const token =localStorage.getItem("token"); // Get token from local storage

  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev); // Toggle dropdown visibility
  };
  
  const handleOptionClick = (subcategory) => {
    const selectedValue = subcategory.categoryTypeId;
  
    // Update selected options
    setSelectedOptions((prevSelected) => {
      const isSelected = prevSelected.some(
        (option) => option.categoryTypeId === selectedValue
      );
  
      // Toggle selection (add if not selected, remove if already selected)
      const newSelectedOptions = isSelected
        ? prevSelected.filter(
            (option) => option.categoryTypeId !== selectedValue
          ) // Remove if already selected
        : [...prevSelected, subcategory]; // Add if not selected
  
      // Update formData with new array of categoryTypeIds
      setFormData((prevFormData) => ({
        ...prevFormData,
        categoryTypeId: newSelectedOptions.map(
          (option) => option.categoryTypeId
        ), // Update the IDs array
      }));
  
      return newSelectedOptions; // Return the updated selected options
    });
  
    // Close the dropdown after selection
        setshowForm(true)

    setIsOpen(false);
  };
  
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };
  const handleUpload = async () => {
    console.log(selectedFiles, 'clicked');
    if (selectedFiles.length === 0) {
      toast.warn('No files selected for upload.');
      console.warn('No files selected for upload.');
      return;
    }
  
    const fileUploadPromises = selectedFiles.map(async (file) => {
      let imgformData = new FormData();
      imgformData.append('postedFile', file);
  
      try {
        const response = await axios.post(`${BASE_URL}/Upload`, imgformData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
          onUploadProgress:(ProgressEvent)=>{
            const percentCompleted=Math.round((ProgressEvent.loaded *100)/ProgressEvent.total);
            setUploadProgress(percentCompleted)
          }
        });
        console.log(response.data, "uploaded")
        console.log(`File uploaded: ${file.name}`);
        return response.data.filePath;
      } catch (error) {
        console.error(`Error uploading ${file.name}:`, error);
        throw error;
      }
    });
  
    try {
      // Wait for all files to be uploaded
      const uploadedFilePaths = await Promise.all(fileUploadPromises);
      
      // Join the file paths into a single string separated by commas
      const imageString = uploadedFilePaths.join(',');
  
      setFormData(prevFormData => ({
        ...prevFormData,
        images: imageString // Set the images as a comma-separated string
      }));
  
      toast.success("All images uploaded successfully!");
    } catch (error) {
      toast.error('Error uploading some files.');
      console.error('Error uploading files:', error);
    }
  };  
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    // Limit the number of images
    const maxImages = 5; // Set the max number of images you want
    if (files.length + selectedFiles.length > maxImages) {
      toast.warn(`You can only upload a maximum of ${maxImages} images.`);
    } else {
      setSelectedFiles([...selectedFiles, ...files]);
    }
  };
  const [formData, setFormData] = useState({
    firstName: "",
    serviceProviderName: "",
    state: "",
    barAssociation: "",
    address: "",
    categoryId: 0,
    categoryTypeId: [],
    servicePlanId: 0,
    paymentPrice: "",
    images: "",
    addtiltle:"",
    Wanted:"",
    workDescription:"",
    whatyouareselling:""
  });
  const removeFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };
  const handleShowForm = (e) => {
    let num = Number(e.target.value);
    setFormData({ ...formData, categoryId: num });
    setshowForm(true);
  };
  const handleServicePlanChange = (price, e) => {
    let num = Number(e.target.value);
    setFormData({ ...formData, servicePlanId: num, paymentPrice: price });
  };
  const handlepayscreen = () => {
    if (formData.categoryId && formData.servicePlanId) {
      setpayscreen(true);
    } else {
      setError("Please select a category and a service plan.");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmitclick = (e) => {
    e.preventDefault();
  }
  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      const formDataToSend = {
        categoryId: formData.categoryId,
        categoryTypeIds: formData.categoryTypeId.join(","),
        servicePlanId: formData.servicePlanId,
        paymentPrice: formData.paymentPrice,
        name: formData.firstName,
        retailerName: formData.serviceProviderName,
        stateBarCouncil: formData.state,
        barAssociationMember: formData.barAssociation,
        address: formData.address,
        addtiltle:formData.addtiltle,
        workDescription:formData.workDescription,
        whatyouareselling:formData.whatyouareselling,
        // Profession:formData.Profession,
        // Work:formData.Work,
        // Material:formData.Material,
        // Machine:formData.Machine,
        Wanted:formData.Wanted,
        Images: formData.images,
        userId:userId
      };
      const response = await axios.post(`${BASE_URL}/Services`, formDataToSend,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      // setLoading(false);
      console.log(response)
      const requiredFields = [
        "firstName",
        "serviceProviderName",
        "address",
        "paymentPrice",
        "images",
      ];
    
      // Check if all required fields are filled
      const isFormDataComplete = requiredFields.every(
        (field) => formData[field] !== null && formData[field] !== undefined && formData[field] !== ""
      );
    
      if (!isFormDataComplete) {
        toast.warn("Please fill all required fields.");
        setError("Field is required");
        setDisplayRazorpay(false);
        return; // Stop execution if any required field is missing
      }

      console.log(response.data, "successful");
      toast.success('service posted successfully')
      // handlepayscreen();
    } catch (error) {
      console.error("Error submitting form:", error.response.request.responseText[0]);
      setError(error.message);
      setLoading(false);
    }
  };

  const createPayment = async () => {
    // e.preventDefault();
    if (userId === undefined || userId === null) {
      toast.error("Please login to proceed with payment.");
    }
    const requiredFields = [
      "firstName",
      "serviceProviderName",
      "address",
      "paymentPrice",
      "images",
    ];
  
    // Check if all required fields are filled
    const isFormDataComplete = requiredFields.every(
      (field) => formData[field] !== null && formData[field] !== undefined && formData[field] !== ""
    );
  
    if (!isFormDataComplete) {
      toast.warn("Please fill all required fields.");
      setError("Field is required");
      setDisplayRazorpay(false);
      return; // Stop execution if any required field is missing
    }
    setDisplayRazorpay(true);
  }


    const handlePayment = async (status, orderDetails) => {
      console.log(status, "---starting- ", orderDetails);
      console.log(displayRazorpay, 'before success');
      if (status === "succeeded") {
        setDisplayRazorpay(false);  // Hide payment interface
        console.log(displayRazorpay, 'after setting to false (immediate, before state update)');
    
        // Submit the form
        await handleSubmit(orderDetails);
        
        // Reset form fields and other states after successful payment
        setFormData([]);
        setSelectedFiles([]);          // Clear selected files
        setSelectedOptions([]);        // Clear selected subcategories
        setcatid(null);                // Reset category ID
        setshowForm(false);            // Hide the form if needed
        setError(null);                // Clear any errors
        toast.success("Form reset successfully after payment!");
    
      } else if (status === "cancelled") {
        setDisplayRazorpay(false);
      }
    };
  useEffect(() => {
    const fetchSubCategories = async () => {
      if (!catid) return; // Prevent fetching if no category selected
      try {
        setLoading(true);
        const subCategoryResponse = await axios.get(
          `${BASE_URL}/Categories/${catid}/categoryType`,{
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          }
        );
        setSubCategories(subCategoryResponse.data);
        setSelectedOptions([]); // Clear selected subcategories when category changes
        setFormData((prevFormData) => ({
          ...prevFormData,
          categoryTypeId: [], // Clear categoryTypeId in formData
        }));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchSubCategories();
  }, [catid]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, servicePlansResponse] = await Promise.all([
          axios.get(`${BASE_URL}/Categories`),
          axios.get(`${BASE_URL}/ServicePlans`),{
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          }
        ]);
        setCategories(categoriesResponse.data);
        setServiceplans(servicePlansResponse.data);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchData();
  }, []);


  const fetchFreeAdd = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/ServicePlans/${userId}/serviceplan`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      const advertisement = response.data;
  
      // Check if any item in the response has propertyFeeId equal to 0
      const hasFreePlan = advertisement.some(fee => fee.servicePlanId === 0);
      
      setFreePlan(hasFreePlan);
      setFreeAdd(advertisement);
      console.log(advertisement, 'property free fees response');
    } catch (error) {
      console.error('Error fetching property fees', error);
    }
  };
  
  useEffect(() => {
    fetchFreeAdd();
  }, []);



  const apiKey = 'rzp_test_4rrCmYtqWUOUvT'
  console.log(apiKey, "apiKey: " + apiKey);
  console.log(formData)
  console.log(categories)
  console.log( typeof catid)

  return (
    <div className="sf-cont">
      <ToastContainer />
      <div className="sf-left-cont">
        <div className="sf-title-sec">
          <div className="sf-title-left">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item c-active">
                  <a href="/home">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="/my-services">Service</a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Service
                </li>
              </ol>
            </nav>
            <p className="third-text">Add Service</p>
          </div>
          <div className="sf-title-right">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setcatid(e.target.value);
                setFormData({
                  ...formData,
                  categoryId: Number(e.target.value),
                  categoryTypeId: [],
                });
              }}
            >
              {categories.map((category, index) => {
                return (
                  <option key={index} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                );
              })}
            </select>
            {catid && (
              <div>
                {/* Display selected subcategory names */}
                <div
                  onClick={handleToggleDropdown}
                  className="form-select" // Using the same class as your <select>
                >
                  {selectedOptions.length > 0
                    ? selectedOptions
                        .map((option) => option.categoryTypeName)
                        .join(", ")
                    : "Select subcategories"}
                </div>

                {/* Dropdown options */}
                {isOpen && (
                  <ul className="dropdown-options">
                    {Subcategories.map((subcategory) => (
                      <li
                        key={subcategory.categoryTypeId}
                        className={`dropdown-option ${
                          selectedOptions.some(
                            (option) =>
                              option.categoryTypeId ===
                              subcategory.categoryTypeId
                          )
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleOptionClick(subcategory)}
                      >
                        {subcategory.categoryTypeName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>   
        {showForm ? (
          <form className="sf-form-input" onSubmit={handleSubmitclick}>
            <div className="sf-2-piece">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
               
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Service provider name"
                    name="serviceProviderName"
                    value={formData.serviceProviderName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              {catid === "2" &&(<div className="row">
                <div className="col">
                  <select
                    className="form-select"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    required
                  ><option className="" disabled>please select a category</option>
                    <option value="">State Bar Council</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Tamilnadu">Tamilnadu</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Gujarat">Gujarat</option>
                  </select>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Bar Association Name"
                    name="barAssociation"
                    value={formData.barAssociation}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>)}
              <div className="row">
      {/* Jobs Field */}
      {catid === "10" && (
        <> <div className="col">
         <input
           type="text"
           className="form-control"
           placeholder="Wanted"
           name="Wanted"
           value={formData.Wanted}
           onChange={handleInputChange}
           required
         />
       </div>
       <div className="row">
              <div className="mb-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  placeholder="please provide some desctption about your job"
                  name="workDescription"
                  value={formData.workDescription}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
       </>
      )}

{catid !== "10" && (
  <>
        <div className="col">
        <input
          type="text"
          className="form-control"
          placeholder="Ad Title"
          name="addtiltle"
          value={formData.addtiltle}
          onChange={handleInputChange}
          required
        />
      </div>
    <div className="row">
    <div className="mb-3">
      <textarea
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="4"
        placeholder="what are you selling"
        name="whatyouareselling"
        value={formData.whatyouareselling}
        onChange={handleInputChange}
        required
      ></textarea>
    </div>
  </div></>)}
    </div>


            </div>
            
            <div className="row">
              <div className="mb-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  placeholder="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="lpm-part-6-1">
      <p style={{padding:"0px"}}>Add Photos</p>
      <div className="lpm-part-6-upload-img" style={{padding:"0px"}}>
        <div className="lpm-part-6-border" onClick={handleBrowseClick}>
          <FaRegImage className="lpm-part-6-image-icon" />
          <button type="button">Browse files</button>
        </div>
      </div>
      <input
        id="fileInput"
        type="file"
        multiple
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div className="lpm-part-6-add-pics">
        {((uploadProgress>0)&&(uploadProgress < 100)) &&(
          <div className="upload-progress">
            <p>Uploading:{uploadProgress}%</p>
            </div>
        )}
        <button onClick={handleUpload}>Submit Photos</button>
      </div>

      {/* Display selected images */}
      <div className="selected-files">
          {selectedFiles.length > 0 && (
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`selected ${index}`}
                    loading="lazy"
                    width="100px"
                    height="100px"
                  />
                  <button
                    onClick={() => removeFile(index)}
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      background: '#ffffffcc',
                      border: 'none',
                      borderRadius:"50%",
                      cursor: 'pointer',
                      color: 'black',
                      fontSize: '12px',
                    }}
                    aria-label="Remove"
                  >
                    ✖
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
    </div>

            {/* {error && <p className="error-message">{error}</p>} */}
            {success && (
              <p className="success-message">Service added successfully!</p>
            )}
          </form>
          
        ) : (
          <p
            className="thir-text"
            style={{

            }}
          >
            Please select a category
          </p>
        )}
       </div>
      <div className="sf-right-up">
 
          
            <div className="sf-right-cont">
              <div className="sf-right-top-cont">
                <div className="sf-right-top-cont-1">
                  <img src={partyicon} loading="lazy" alt="" />
                  <p className="sf-top-cont-desc">Free Trial For 3 Months</p>
                </div>
                <div className="sf-right-top-cont-2">
                  <div className="sf-right-top-cont-2-text">
                    <p className="sf-right-top-cont-2-text1">
                      Post your services and grow your business
                    </p>
                    <p className="sf-right-top-cont-2-text2">
                      Post your services with the lowest prices and get
                      notified.
                    </p>
                  </div>
               {freePlan ?null:<div className="sf-right-top-cont-2-bills">
                    {serviceplans.map((servicePlan, id) => (
                      <div key={id} className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={servicePlan.servicePlanId} // Correctly set the value to servicePlan.id
                          // id={`flexCheckDefault${id + 1}`}
                          name="servicePlan"
                          onChange={(e) =>
                            handleServicePlanChange(servicePlan.servicePrice, e)
                          } // Correctly call the handler
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexCheckDefault${id + 1}`}
                        >
                          <span>₹{servicePlan.servicePrice}</span> /Month
                        </label>
                      </div>
                    ))}
                  </div>}
                  {displayRazorpay && (
        <RenderRazorpay
          amount={formData.paymentPrice*100}
          currency={"INR"}
          // orderId={orderDetails.orderId}
          keyId={apiKey}
          keySecret={process.env.NEXT_PUBLIC_APP_RAZORPAY_KEY_SECRET}
          handlePayment={handlePayment}
          name={localStorage.getItem('username')}
        // name={capitalizeFirstLetter(helper?.loginUserDetail.firstName) + ' ' + capitalizeFirstLetter(helper?.loginUserDetail.lastName)}
        />
      )}

                </div>
              </div>
      {freePlan ?
      <div className="lpm-part-6-final">
      <button
        className="lpm-final-button"
        onClick={() => {
          handleSubmit();
        }}
      >
        1 Month/Free
      </button>
    </div>:
              <div className="sf-right-bottom-cont">
                <button
                  className="btn btn-primary btn-lg btn-block"
                  onClick={() => {
                    createPayment()
                  }}
                >
                  Continue
                </button>
              </div>}
            </div>
       
      </div>
    </div>
  );
};

export default ServiceForm;

