import React from "react";
import appartment from "../../Assets/appartment.svg";
const token=localStorage.getItem("token");
const PropertyReq = ({ image, title, price, onClick }) => {
  // Use a mapping between image names and imports
  // const imageMap = {
  //   "appartment.svg": appartment,
    // Add other image imports here if necessary
  // };
// console.log(image,'kjvjnk')
let disimg = image;
if (disimg == null){
  disimg = appartment;
}
return (
    <div
      className="property-card"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div className="property-image">
        <img src={disimg} loading="lazy"  alt={title} className="property-image" />
      </div>
      <div className="property-card-content">
        <p className="property-title">{title}</p>
        <p className="property-price">{price} Properties</p>
      </div>
    </div>
  );
};

export default PropertyReq;
