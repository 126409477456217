import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import Chatpopup from './v-Chat-popup';
// import { ArrowBack } from '@material-ui/icons';
import picimg from '../../Assets/Gallery.svg'
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";
import nofav from '../../Assets/No Fav.png';

import { useNavigate ,useLocation} from 'react-router-dom';
import '../../Styles/v-Categoriepage.css';
// import firsthouse1 from '../../Assets/firsthouse1.png';
// import firsthouse2 from '../../Assets/firsthouse2.png';
// import firsthouse3 from '../../Assets/firsthouse3.png';
// import firsthouse4 from '../../Assets/firsthouse4.png';
// import firsthouse5 from '../../Assets/firsthouse5.png';
// import firsthouse6 from '../../Assets/firsthouse6.png';

const ProfileDetails = () => {
  // const { id } = useParams(); 
  const [isChatOpen, setIsChatOpen] = useState(false);  // Chat toggle state
  const [serviceDetails, setServiceDetails] = useState([]);
  console.log(serviceDetails, "ServiceDetails")
  const { servicesId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(servicesId, "ServicesId");
  const token=localStorage.getItem('token');


  const encodeUri = (val) => {
    // Check if val is not null or undefined
    if (val && typeof val === 'string') {
      // Check if the value starts with 'http'
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }

    // Handle the case where val is null or undefined
    console.warn("Provided value is null or undefined, returning empty string.");
    return ""; // Or handle it as you see fit
  };
  const decodeImgeString = (str) => {
    if (str) {
      // Split the string by commas to get an array of image paths
      const imageArray = str.split(",");
      // Return the array of encoded image URLs
      return imageArray.map((image) => encodeUri(image.trim()));
    }
    return []; // Return an empty array if the string is null or empty
  };


  // Toggle Chat Visibility
  const handleChatClick = () => {
    // setIsChatOpen(prevState => !prevState);
    alert("Download mobile app to make chat and calls")
  };

  const fetchServiceDetails = async () => {
    console.log("hiii")
    try {

      const response = await axios.get(`${BASE_URL}/Services/${servicesId}`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      console.log(response.data, 'service details');
      setServiceDetails(response.data);
    } catch (error) {
      console.error('Error fetching services', error);
    }
  };
  useEffect(() => {
    fetchServiceDetails();
  }, [servicesId]);



  // Find the specific service based on the ID from params
  // const service = serviceDetails.find(s => s.servicesId == parseInt(servicesId ));

  // // Fallback if no service is found
  // if (!service) {
  //   return <div>Service not found</div>;
  // }
  console.log(serviceDetails,'all det')
  const categoryid = location.state.categoryId;
  const categoryName = location.state?.catname; // Accessing directly
  console.log(categoryid,'3' , categoryName)
  return (
    <>
      <div className="profile-container" key={serviceDetails.servicesId}>
        <div className="profile-left">
        <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item c-active">
                  <a href="/home">Home</a>
                </li>
                <li className="breadcrumb-item c-active">
                <a href={'/home'}>Categories</a>
                </li>
                <li className="breadcrumb-item c-active">
                <a href={`/category/${categoryid}`}>{categoryName}</a>
                </li>
                <li className="breadcrumb-item ">
                  <a>{serviceDetails.name}</a>
                </li>
               
              </ol>
            </nav>
        <div className="profile-image-section">
          {(serviceDetails.images?.length >0)?
              (<div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                  {decodeImgeString(serviceDetails.images).map((image, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <img
                        src={image}
                        alt={`Villa Image ${index + 1}`}
                        className="profile-image"
                      />
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="prev"
                  style={{ width: "5%" }}
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="next"
                  style={{ width: "5%" }}
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>        
                <div className="vd-count-pics">
  <img src={picimg} alt="" />
  <p>
    {serviceDetails.images ? serviceDetails.images.split(',').filter(Boolean).length : 0} Photos
  </p>
</div>

              </div>):(
                <div className="no-image-section">
                  <img src={nofav} alt =''className="no-image" />
                  <p>No Image Available</p>
                </div>
              )}
            </div>
          <div className="personal-details">
            <h2>Personal Details</h2>
            <p><strong>Name</strong>{serviceDetails.retailerName}</p>
            <p><strong>Name of State Bar Council</strong> {serviceDetails.stateBarCouncil}</p>
            <p><strong>Name of Bar Association (Member)</strong> {serviceDetails.stateBarCouncil}</p>
            <h2>Contact Details</h2>
            <p><strong>Email</strong> {serviceDetails.email}</p>
            <p><strong>Address</strong>{serviceDetails.address}</p>
          </div>
          <div className="description-section">
            <h2>Description</h2>
            <p>  Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!</p>
          </div>
        </div>
        <div className="profile-right">
          <div className="ad-details">
            <p><strong>AD ID:</strong> 1234 5678 9876</p>
            <p><FaMapMarkerAlt className="icon" />{serviceDetails.address}</p>
            <p><FaCalendarAlt className="icon" /> {serviceDetails.createdDate}</p>
          </div>
          <div className="map-section">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?... (actual map src)"
              width="100%"
              height="400px"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
          <div className="action-item" onClick={handleChatClick}>
            <IoChatbubbleEllipsesOutline className="icon" />
            <h5>Chat</h5>
          </div>
        </div>

        {/* Conditionally Render Chat in the middle */}
        {isChatOpen && (
          <div className="chat-overlay">
            <Chatpopup closeChat={() => setIsChatOpen(false)} />
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileDetails; 