import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoimg from "../../Assets/logoimg.png";
import "../../Styles/Otp.css";
import { useLocation } from "react-router-dom";

const Otp = () => {
  const location = useLocation();
  const otpFromLogin = location.state?.otp;
  const [code, setCode] = useState(["", "", "", ""]);
  const [isError, setIsError] = useState(false); // Track if OTP is incorrect
  const navigate = useNavigate();

  const handleChange = (value, index) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      setIsError(false); // Reset error state on change

      if (value && index < 3) {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && code[index] === "") {
      if (index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      }
    }
  };

  const handleEditPhoneNumber = () => {
    navigate("/login");
  };

  const onSubmitOtp = () => {
    const enteredOtp = code.join("").trim();
    const trimmedOtpFromLogin = otpFromLogin;
    if (enteredOtp === trimmedOtpFromLogin) {
      navigate("/home");
    } else {
      setIsError(true); // Set error state if OTP is incorrect
    }
  };

  useEffect(() => {
    if (isError) {
      alert("Invalid OTP"); // Show alert only when OTP is incorrect
    }
  }, [isError]);
  console.log(isError,'otp')
  return (
    <div className="vp-background">
      <div className="vp-overlay">
        <div className="vp-logo-container">
          <img src={logoimg} loading="lazy" alt="First House Logo" className="vp-logo2" />
          <h1 className="vp-company-name">First House</h1>
        </div>

        <div className="vp-verification-container">
          <h2 className="vp-h2">Verify Phone Number</h2>
          <p className="vp-p">
            Please enter the 4 digit code sent to +91 98765 43210 through SMS
          </p>
          <a href="#" className="vp-edit-phone" onClick={handleEditPhoneNumber}>
            Edit your phone number?
          </a>

          <div className="vp-input-group">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`input-${index}`}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className="vp-input-box"
                style={{
                  border: isError ? "2px solid red" : "1px solid #ccc" // Apply inline style for error
                }}
              />
            ))}
          </div>
          <button className="vp-submit-button" onClick={onSubmitOtp}>
            Submit OTP
          </button>

          <p className="vp-resend">
            Haven't got the confirmation code yet? <br />
            <a href="#" className="vp-resend-link">
              Try again via
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Otp;
