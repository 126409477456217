import React, { useEffect, useState } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import "../../Styles/v-categories.css";
import noservice from '../../Assets/OBJECTS.svg'
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";

const LegalServices = () => {
  const navigate = useNavigate();
  const location= useLocation();
  const [categoryService, setCategoryService] = useState([]);
  const [advertisement, setAdvertisement] = useState("");
  const [subCategories, setSubCategories] = useState([]); // For subcategory filters
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // To track selected subcategory
  const { categoryId } = useParams();
  const { catname } = location.state || {}; // Using || {} to avoid undefined error
  const token =localStorage.getItem("token");
  useEffect(() => {
    console.log('Category Name:', catname); // Check if catname is logged correctly
}, [catname]);  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };
  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };

  // Fetch services based on category and subcategory
  const fetchCategoryTypeServices = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Services/${categoryId}/categoryTypeService`,{
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      const { vmlist = [] } = response.data;
      setCategoryService(vmlist);
    } catch (error) {
      console.error("Error fetching services", error);
    }
  };

  // Fetch subcategories based on categoryId
  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Categories/${categoryId}/categoryType`,{
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      console.log(response.data,'insidde catpg')
      setSubCategories(response.data); // Set subcategories
    } catch (error) {
      console.error("Error fetching subcategories", error);
    }
  };

  const fetchAdvertisements = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Advertisements/randomAd`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      console.log(response)
      setAdvertisement(response.data);
    } catch (error) {
      console.error("Error fetching advertisements", error);
    }
  };

  // Fetch both services and subcategories when categoryId changes
  useEffect(() => {
    fetchCategoryTypeServices();
    fetchSubCategories();
    fetchAdvertisements();
  }, [categoryId]);

  // Handle subcategory selection
  const handleSubCategoryChange = (event) => {
    const subCategoryId = event.target.value;
    setSelectedSubCategory(subCategoryId);
  };

  // Filter services based on selected subcategory
  const filteredServices = categoryService.filter((service) => {
    const serviceSubCategoryIds = (service.categoryTypeIds || "")
      .split(",")
      .map((id) => id.trim())
      .filter(Boolean); // Exclude empty strings

    // Exclude services with null or undefined categoryTypeIds
    if (!service.categoryTypeIds) {
      return false;
    }

    return selectedSubCategory
      ? serviceSubCategoryIds.includes(selectedSubCategory) // Include only if selected
      : true; // Include all if no subcategory is selected
  });
console.log(filteredServices,'skjvnij ')
  return (
    <div>
      {/* Subcategory filter dropdown */}
    

        <div className="legal-services-layout-container">
          <div className="legal-services-container">
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
            <div style={{display:"flex",flexDirection:"column",gap:"16px"}}>
            <div className="serv-breadcrumb-line" >
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb" style={{margin:"0"}}>
              <li className="breadcrumb-item">
                <a href="/home">Home</a>
              </li>
              <li className="breadcrumb-item " aria-current="page">
                <a href="/home">Categories</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href="/home">{catname}</a>
              </li>
            </ol>
          </nav>
        
        </div>
              <h1>{filteredServices.length} services found</h1>
            </div>

            <div className="subcategory-filter" style={{display:"flex",gap:"20px",alignItems:"center"}}>
        <label htmlFor="subcategory" className="subcategory">Filter by Subcategory:</label>
        <select
          id="subcategory"
          className="form-select"
          value={selectedSubCategory || ""}
          onChange={handleSubCategoryChange}
        >
          <option value="">All</option>
          {subCategories.map((subCategory) => (
            <option
              key={subCategory.categoryTypeId}
              value={subCategory.categoryTypeId}
            >
              {subCategory.categoryTypeName}
            </option>
          ))}
        </select>
      </div>
            </div>
            {filteredServices.length > 0 ? (

            <div className="legal-services-grid">
              {filteredServices.map((service) => (
                <div
                  key={`${service.categoryId}-${service.servicesId}`} // Combine values to ensure uniqueness
                  className="legal-services-card"
                  onClick={() => navigate(`/categorie/${service.servicesId}`, { state: { categoryId: service.categoryId,catname: service.categoryName  } })}
                  >
                  <img
                    src={decodeImgeString(service.images)}
                    alt={service.servicePlanName}
                    loading="lazy"
                    className="legal-services-image"
                  />
                  
                  <div style={{padding:" 0 20px 10px 20px"}}>
                  <h3>{service.name}</h3>
                  <p>{service.categoryName}</p>
                  <p className="legal-services-icon-text">
                    <FaMapMarkerAlt className="legal-services-icon" />{" "}
                    {service.stateBarCouncil}
                  </p>
                  <p className="legal-services-icon-text">
                    <FaCalendarAlt className="legal-services-icon" />{" "}
                    {new Date(service.createdDate).toLocaleDateString()}
                  </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
        <div className="nodata">
          <img src={noservice} alt='' />
          <p>No Services</p>
        </div>
      )}
          </div>
          <div className="mylisting-add1" key={advertisement.advertisementId}style={{height:"80%"}}>
            <img
              src={encodeUri(advertisement.imageUrl)}
              loading="lazy"
              alt=""
              className="advertisement"
            />
          </div>
        </div>
     
    </div>
  );
};

export default LegalServices;
