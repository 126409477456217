import React from "react";
import VillasList from "../../Components/HomeComponent/Villas"; // Assuming the import is correct
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Services/BaseUrl";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
const VillaList = ({ comfortLiv, loading }) => {
  const navigate = useNavigate();

  const encodeUri = (val) => {
    // Check if val is not null or undefined
    if (val && typeof val === 'string') {
      // Check if the value starts with 'http'
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }
    
    // Handle the case where val is null or undefined
    console.warn("Provided value is null or undefined, returning empty string.");
    return ""; // Or handle it as you see fit
  };
  

  if (loading) {
    return <p>Loading...</p>; // Handle loading state
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440, // Between 1024px and 1440px
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      }, 
      {
        breakpoint: 1300, // Between 1024px and 1440px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },  
      {
        breakpoint: 1162, // Between 1024px and 1440px
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      }, 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },    
    ],
  };

  
  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };
  console.log(comfortLiv,'inside comfortliv')
  return (
    <div className="NewProperty-container">
      <div className="component-title">
        <p className="newlyListed">Explore Villas</p>
        <p className="newProperty-title">Comfort Living Solution</p>
      </div>
      <div className="slider-container">
                <div className='comfort-living-container'>
                <Slider {...settings}
        initialSlide={2}
        infinite
        // prevArrow={<PreviousBtn />}
        // nextArrow={<NextBtn />}
        dotsClass="slick-dots custom-indicator"
>        
        {comfortLiv &&
          Array.isArray(comfortLiv) &&
          comfortLiv.map((villa) => (
            <VillasList
              key={villa.propertyId} // Ensure a unique key
              favouriteId={villa.favouriteId}
              propertyId={villa.propertyId}
              title={villa.adTitle}
              location={villa.locationName}
              area={villa.area}
              rooms={villa.noOfRooms}
              price={villa.propertyPrice}
              image={decodeImgeString(villa.images)} // Ensure the image is encoded properly
            />  
          ))}
            </Slider>
            </div>
      </div>
    </div>
  );
};

export default VillaList;
