import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import "../../Styles/VillaList.css";
import Villa from "../../Components/HomeComponent/Villas";
import image from "../../Assets/FH_image.svg";
import { BASE_URL } from "../../Services/BaseUrl";
import { useParams } from "react-router-dom";

const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]); // Stores properties after filtering by propertyTypeId
  const [sellTypes, setSellTypes] = useState([]);
  const [sellTypeId, setSellTypeId] = useState("");
  const [categories, setCategories] = useState([]);
  const [propertyTypeId, setPropertyTypeId] = useState("all"); // Set default to "all"
  // const { categoryId } = useParams();
const token=localStorage.getItem("token");
  const decodeImageString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };

  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val;
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`;
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/SellTypes/${sellTypeId}/propertytypes`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setProperties(response.data); // Store all properties
      filterProperties(response.data); // Apply initial filter
      console.log(response.data, "properties fetched based on sellTypeId...");
    } catch (error) {
      console.error("Error fetching properties", error);
    }
  };

  const filterProperties = (allProperties) => {
    // Filter properties based on selected propertyTypeId
    if (propertyTypeId === "all") {
      setFilteredProperties(allProperties); // Show all properties
    } else {
      const filtered = allProperties.filter((property) => 
        property.propertyTypesId === Number(propertyTypeId)
      );
      console.log(`Filtering properties with propertyTypeId: ${propertyTypeId}`);
      console.log('All properties:', allProperties);
      console.log('Filtered properties:', filtered);
      setFilteredProperties(filtered);
    }
  };

  const fetchSellTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/SellTypes`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setSellTypes(response.data);

      if (response.data.length > 0) {
        setSellTypeId(response.data[0].sellTypeId);
      }
    } catch (error) {
      console.error("Error fetching sell types", error);
    }
  };
  const fetchCategoriesTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Types/${sellTypeId}/categoryType`,{
        params: {
           categoryId: 1
        },
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
      });
      setCategories([{ typeId: "all", typesName: "All" }, ...response.data]); // Add "All" option
      console.log(response.data, "category types...");
    } catch (error) {
      console.error("Error fetching category types", error);
    }
  };

  useEffect(() => {
    fetchSellTypes();
  }, []);

  useEffect(() => {
    setPropertyTypeId("all"); // Reset propertyTypeId to default "all" when sellTypeId changes
    fetchProperties();
    fetchCategoriesTypes(); // Fetch categories each time sellTypeId changes
  }, [sellTypeId]);
  ; // Refetch properties and categories when sellTypeId changes

  useEffect(() => {
    filterProperties(properties); // Refine filtering whenever propertyTypeId changes
  }, [propertyTypeId, properties]); // Include properties in dependency array

  return (
    <>
    <div className="villalist-container">
      <div className="villalist-content">
        <h3>Properties</h3>
        <div className="filter">
          <select
            className="select-box"
            onChange={(e) => setSellTypeId(e.target.value)}
            value={sellTypeId}
          >
            {sellTypes.map((sellType) => (
              <option key={sellType.sellTypeId} value={sellType.sellTypeId}>
                {sellType.sellTypeName}
              </option>
            ))}
          </select>
        </div>
        <div className="filter">
          <select
            className="select-box"
            onChange={(e) => setPropertyTypeId(e.target.value)} // Update propertyTypeId on change
            value={propertyTypeId}
          >
            {categories.map((category) => (
              <option key={category.typeId} value={category.typeId}>
                {category.typesName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="villalist-content-items">
        {filteredProperties.map((villa) => (
          <Villa
            key={villa.propertyId}
            propertyId={villa.propertyId}
            favouriteId={villa.favouriteId}
            title={villa.propertyType}
            location={villa.locationName}
            area={villa.area}
            price={villa.propertyPrice}
            rooms={villa.noOfRooms}
            image={decodeImageString(villa.images)}
            isInitiallyFavourite={villa.favouriteId > 0}
          />
        ))}
      </div>

      <div className="fh_image">
        <img src={image} loading="lazy" alt="Featured Housing" />
      </div>
      <ToastContainer />
    </div>
    </>
  );
};

export default Properties;
