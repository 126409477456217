import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../Styles/Mylisting2.css";
import BuildingIcon from "../../Assets/mlbuilding.png";
import AreaIcon from "../../Assets/mlarea.png";
import BedIcon from "../../Assets/mlbed.png";
import ParkingIcon from "../../Assets/mlparking.png";
import BathIcon from "../../Assets/mlbath.png";
import AminityIcon from "../../Assets/mlaminities.png";
import featuredad from "../../Assets/mlfeaturedadd.png";
import mlhosting from "../../Assets/mlstillneedhosting.png";
import mlarea3 from "../../Assets/mlarea3.png";
import mllocation from "../../Assets/fhlocation.png";
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";

const ListingPage = () => {
  const listingDetailsArray = [
    { label: "Price Breakup", value: " priceBreakup", icon: AminityIcon },
    { label: "RERA ID", value: " reraID ", icon: AminityIcon },
    { label: "Address", value: "address ", icon: AminityIcon },
    { label: "Furnishing", value: "furnishing", icon: AminityIcon },
    { label: "Loan Offered", value: "loanOffered", icon: AminityIcon },
    { label: "Additional Rooms", value: " additionalRooms", icon: AminityIcon },
    {
      label: "Water Availability",
      value: " waterAvailability",
      icon: AminityIcon,
    },
    {
      label: "Status of Electricity",
      value: "electricityStatus",
      icon: AminityIcon,
    },
    { label: "Lifts", value: "lifts", icon: AminityIcon },
  ];
  const { id } = useParams();
  const [listingData, setListingData] = useState(null);
  const [advertis, setAdvertis] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token=localStorage.getItem("token");

  const encodeUri = (val) => {
    // Check if val is not null or undefined
    if (val && typeof val === "string") {
      // Check if the value starts with 'http'
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }

    // Handle the case where val is null or undefined
    console.warn(
      "Provided value is null or undefined, returning empty string."
    );
    return ""; // Or handle it as you see fit
  };

  useEffect(() => {
    const fetchListingData = async () => {
      console.log("entered function");
      try {
        console.log("entered try block");
        const response = await axios.get(`${BASE_URL}/Properties/${id}`,          {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });
        console.log("executed API");
        console.log(response);
        setListingData(response.data.vm);
        setAdvertis(response.data.advertisements);
      } catch (error) {
        console.error("Error fetching listing data:", error);
        setError("Failed to load listing data. Please try again later.");
      }
    };

    fetchListingData();
  }, [id]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!listingData) {
    return <p>Loading...</p>;
  }
  const decodeImgeString = (str) => {
    if (str) {
      // Split the string by commas to get an array of image paths
      const imageArray = str.split(",");
      // Return the array of encoded image URLs
      return imageArray.map((image) => encodeUri(image.trim()));
    }
    return []; // Return an empty array if the string is null or empty
  };

  return (
    <div className="ml2">
      <div className="ml2-listing-page">
        <header className="ml2-listing-header">
          <button
            className="ml2-back-button"
            onClick={() => navigate("/Mylisting1")}
          >
            ← My Listings
          </button>
          {/* <select className="ml2-filter-select">
            <option>All Listings</option>
            <option>Active</option>
            <option>Sold</option>
          </select> */}
        </header>
        <div className="ml2-image-section">
          <>
          <div className="house-container">
              <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                  {decodeImgeString(listingData.images).map((image, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <img
                        src={image}
                        alt={`Villa Image ${index + 1}`}
                        className="ml2-house-image"
                      />
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="prev"
                  style={{ width: "5%" }}
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="next"
                  style={{ width: "5%" }}
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div></>
          <div className="ml2-image-overlay">
            <div className="ml2-listing-icons">
              <div className="ml2-icon-container">
                <img
                  src={BuildingIcon}
                  alt="Building"
                  loading="lazy"
                  className="ml2-icon"
                />
                <p className="ml2-proptype">Property Type</p>
                <p className="ml2-propprop">{listingData.propertyType}</p>
              </div>
              <div className="ml2-icon-container">
                <img
                  src={AreaIcon}
                  loading="lazy"
                  alt="Area"
                  className="ml2-icon"
                />
                <p className="ml2-proptype">Area</p>
                <p className="ml2-propprop">{listingData.area} Sq</p>
              </div>
              <div className="ml2-icon-container">
                <img
                  src={BedIcon}
                  loading="lazy"
                  alt="Beds"
                  className="ml2-icon"
                />
                <p className="ml2-proptype">No. of Bedrooms</p>
                <p className="ml2-propprop">{listingData.noOfRooms} Beds</p>
              </div>
              <div className="ml2-icon-container">
                <img
                  src={ParkingIcon}
                  loading="lazy"
                  alt="Parking"
                  className="ml2-icon"
                />
                <p className="ml2-proptype">Parkings</p>
                <p className="ml2-propprop">{listingData.parking} Parkings</p>
              </div>
              <div className="ml2-icon-container">
                <img
                  src={BathIcon}
                  loading="lazy"
                  alt="Baths"
                  className="ml2-icon"
                />
                <p className="ml2-proptype">No. of Bathrooms</p>
                <p className="ml2-propprop">{listingData.bathrooms} Baths</p>
              </div>
            </div>
          </div>
        </div>
        <div className="ml2-details-section">
        <h6>Title :</h6>
          <p className="ml2-description-text">{listingData.adTitle}</p>
          <h6>Description :</h6>
          <p className="ml2-description-text">{listingData.description}</p>

          <h6>More Details</h6>
          <div className="details-grid">
          {/* {listingData.propertyPrice &&  <div className="mldetails">
              <p className="mldetque">Price Breakup</p>
              <p className="mldetans">: ₹ {listingData.propertyPrice}</p>
            </div>} */}
         {listingData.propertyPrice ===0? null:  <div className="mldetails">
              <p className="mldetque">Price Break Up </p>
              <p className="mldetans">: {listingData.propertyPrice}</p>
            </div>}
            {listingData.totalFloors &&   <div className="mldetails">
              <p className="mldetque">Total Floors</p>
              <p className="mldetans">: {listingData.totalFloors}</p>
            </div>}
            {listingData.noOfRooms &&   <div className="mldetails">
              <p className="mldetque">Floor No</p>
              <p className="mldetans">: {listingData.noOfRooms}</p>
            </div>}
            {listingData.floorNo &&   <div className="mldetails">
              <p className="mldetque">No.Of Rooms</p>
              <p className="mldetans">: {listingData.floorNo}</p>
            </div>}
            {listingData.balconies &&   <div className="mldetails">
              <p className="mldetque">Balconies</p>
              <p className="mldetans">: {listingData.balconies}</p>
            </div>}

            {listingData.carParking &&   <div className="mldetails">
              <p className="mldetque">Car Parking</p>
              <p className="mldetans">: {listingData.carParking}</p>
            </div>}
            {listingData.carpetArea &&   <div className="mldetails">
              <p className="mldetque">Carpet Area</p>
              <p className="mldetans">: {listingData.carpetArea}</p>
            </div>}
            {listingData.constructionStatus &&   <div className="mldetails">
              <p className="mldetque">Construction Status</p>
              <p className="mldetans">: {listingData.constructionStatus}</p>
            </div>}
          {listingData.address &&  <div className="mldetails">
              <p className="mldetque">Address</p>
              <p className="mldetans">: {listingData.address}</p>
            </div>}
           {listingData.furnishedStatusName && <div className="mldetails">
              <p className="mldetque">Furnishing</p>
              <p className="mldetans">: {listingData.furnishedStatusName}</p>
            </div>}
         {listingData.parking &&   <div className="mldetails">
              <p className="mldetque">Parking</p>
              <p className="mldetans">: {listingData.parking}</p>
            </div>}
            {listingData.roadWidth &&   <div className="mldetails">
              <p className="mldetque">Road Width</p>
              <p className="mldetans">: {listingData.roadWidth}</p>
            </div>}
         {listingData.facing &&   <div className="mldetails">
              <p className="mldetque">Facing</p>
              <p className="mldetans">: {listingData.facing}</p>
            </div>}
          {listingData.locationName &&  <div className="mldetails">
              <p className="mldetque">Location</p>
              <p className="mldetans">: {listingData.locationName}</p>
            </div>}
        { listingData.availableFrom &&<div className="mldetails">
              <p className="mldetque">Availabile From</p>
              <p className="mldetans">: {listingData.availableFrom}</p>
            </div>}
         {listingData.possessionStatusName &&   <div className="mldetails">
              <p className="mldetque">Possession Status</p>
              <p className="mldetans">: {listingData.possessionStatusName}</p>
            </div>}
          </div>

          <h2>Amenities</h2>
          {/* {listingData.amenities && ( */}
          <div className="ml2-amenities-grid">
            {listingDetailsArray.map((detail, index) => (
              <div className="ml-amenity-details" key={index}>
                <img src={detail.icon} loading="lazy" alt="" />
                <p className="mldetans" style={{ margin: "4px" }}>
                  {detail.value}
                </p>
              </div>
            ))}
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="ad-container">
        <div className="ml3-adidfea">
          <div className="ad-id">AD ID: {advertis[1].advertisementId}</div>
          <div className="ad-feature">
            <img
              src={encodeUri(advertis[0].imageUrl)}
              loading="lazy"
              alt="Ad Image"
            />
          </div>
        </div>
        <div className="ad-image">
          <img
            src={encodeUri(advertis[1].imageUrl)}
            loading="lazy"
            alt="Ad Image"
          />
        </div>
        <div className="ml3-pridetbtn">
          <div className="ad-price">₹  {listingData.propertyPrice}</div>
          <div className="ad-details">
            <p>{listingData.address}</p>
            <div className="ml3locarea">
              <div className="ml3loc">
                <img src={mllocation} loading="lazy" alt="" />
                <div className="mlmhaloc">{listingData.locationName}</div>
              </div>
              <div className="ml3loc">
                <img src={mlarea3} loading="lazy" alt="" />
                <div className="mlmhaloc">
                  {listingData.rooms}BHK - {listingData.area} sq
                </div>
              </div>
            </div>
          </div>
          <div className="ml3-expbtn">
            <p className="expiry">
              Your AD expires on {listingData.expiryDate}
            </p>

            <div className="ad-buttons">
              <button className="edit-btn">Edit</button>
              <button className="remove-btn">Remove</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingPage;
