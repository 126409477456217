import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../Styles/ListProperty.css";
import ReactDOM from 'react-dom';
import buildings from "../../Assets/buildings.png";
import smileCircle from "../../Assets/smileCircle.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../Services/BaseUrl";

const ListProperty = () => {
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedPurposeId, setSelectedPurposeId] = useState(null);
  const [sellTypes, setSellTypes] = useState([]);
  const [sellerTypes, setSellerTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [contactNumber, setContactNumber] = useState(""); // Initialize as empty string
  const [contactError, setContactError] = useState(""); // Error state for contact number
  const userId = localStorage.getItem('userId') 
  console.log(userId);
  const token =localStorage.getItem('token');
  const navigate = useNavigate();

  // Handle contact number input
  // const handleContactNumberChange = (e) => {
  //   const value = e.target.value;

  //   // Validate contact number input (allow optional '+' and only digits)
  //   // if (/^\+?\d*$/.test(value) && value.length <= 15) {
  //   if (/^[1-9]\d{9}$/.test(value)) {
  //     setContactNumber(value);
  //     setContactError(""); // Clear error if valid
  //   } else {
  //     setContactError("Please enter a valid contact number."); // Set error if invalid
  //   }
  // };


  
  const handleContactNumberChange = (e) => {
    const input = e.target.value;

    // Check if input contains only numbers and limit to 10 digits
    if (/^\d*$/.test(input) && input.length <= 10) {
      setContactNumber(input);
      
      // Check if input length is 10 to reset or show the error message
      if (input.length === 10) {
        setContactError("");
      } else {
        setContactError("Please enter a 10-digit valid phone number");
      }
    }
  };
  




  // Fetch data from API
  const fetchData = async () => {
    setLoading(true);
    try {
      const [sellTypeResponse, sellerTypesResponse] = await Promise.all([
        axios.get(`${BASE_URL}/SellTypes`),
        axios.get(`${BASE_URL}/SellerTypes`),
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      ]);
      setSellTypes(sellTypeResponse.data);
      setSellerTypes(sellerTypesResponse.data);
      setSelectedRoleId(sellerTypesResponse.data[0]?.sellerTypeId);
      setSelectedPurposeId(sellTypeResponse.data[0]?.sellTypeId);
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Navigate to the next page with selected sellTypeId and sellerTypeId
  const handleNavigation = async () => {
    // Check if userId exists in local storage
    if (!userId) {
      toast.warn("Please login to proceed further");
      return; // Exit the function if the user is not logged in
    }
  
    // Validate the contact number and selected IDs before sending the request
    if (contactError !== "" || !contactNumber || !selectedRoleId || !selectedPurposeId) {
      toast.warn("Please complete all fields correctly before proceeding.");
      return; // Exit if there are any errors or incomplete fields
    }
  
    const senddata = {
      mobileNumber: contactNumber,
      sellerTypeId: selectedRoleId,
      sellTypeId: selectedPurposeId,
      userId: userId,
    };
    
    try {
      const response = await axios.post(`${BASE_URL}/Sellers`, senddata,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      console.log(response.data.sellerId);
      
      // Navigate to the next page with the necessary data
      navigate("/selling-property", {
        state: {
          contactNumber,
          selectedRoleId,
          selectedPurposeId,
          sellerId: response.data.sellerId, // Use the value directly
        },
      });
    } catch (error) {
      console.error("Error while submitting data:", error);
      // Handle the error as necessary
      toast.error("An error occurred while submitting your data.");
    }
  };
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="lp-container" style={{display:"flex",flexDirection:"column",gap:"30px",position:"relative",zIndex:"1",marginTop:"-100px"}}>
{ReactDOM.createPortal(
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          draggable
          pauseOnHover
          pauseOnFocusLoss
          style={{ zIndex: 99999 }}
        />,
        document.body // Renders ToastContainer directly inside body
      )}
            <div className="lp-background1">
        <div className="lp-left-content">
          <h1>
            Post your property to <span>Sell</span> online
          </h1>
          <div className="lp-options">
            <button>
              <img src={buildings} loading="lazy" alt="buildings" className="lp-img-3" />
              Get Noticed with Priority Listings
            </button>
            <button>
              <img src={buildings} loading="lazy" alt="buildings" className="lp-img-3" />
              Sell Faster with Premium Service
            </button>
            <button>
              <img src={smileCircle} loading="lazy" alt="smileCircle" className="lp-img-3" />
              Connect with Verified Buyers 
            </button>
          </div>
        </div>

        {/* Main form */}
        <div className="lp-form">
          <div className="lp-form-1">
            <div className="lp-form-ready">Let's get you started...!</div>
            <div className="lp-form-2">
              {/* Seller Type Selection */}
              <div className="lp-form-2-1">
                <p>You are</p>
                <div className="lp-form-options">
                  {sellerTypes.map((seller) => (
                    <button
                      key={seller.sellerTypeId}
                      className={selectedRoleId === seller.sellerTypeId ? "active" : ""}
                      onClick={() => setSelectedRoleId(seller.sellerTypeId)}
                    >
                      {seller.sellerTypeName}
                    </button>
                  ))}
                </div>
              </div>

              {/* Sell Type Selection */}
              <div className="lp-form-2-2">
                <p>You are here to</p>
                <div className="lp-form-options-1">
                  {sellTypes.map((sellType) => (
                    <button
                      key={sellType.sellTypeId}
                      className={selectedPurposeId === sellType.sellTypeId ? "active" : ""}
                      onClick={() => setSelectedPurposeId(sellType.sellTypeId)}
                    >
                      {sellType.sellTypeName}
                    </button>
                  ))}
                </div>
              </div>

              {/* Contact Number Input */}
              <div className="lp-form-2-3">
                <p>Enter Your Contact Number</p>
                <input
                  type="number"
                  value={contactNumber}
                  placeholder="0000 000 000"
                  className="lp-form-input"
                  style={{ backgroundColor: "#00475b" }}
                  onChange={handleContactNumberChange}
                />
                {contactError && <p className="error-message">{contactError}</p>} {/* Display error message */}
              </div>

              {/* Start Now Button */}
              <button
                className="lp-start-button"
                onClick={handleNavigation}
                disabled={!contactNumber || !selectedRoleId || !selectedPurposeId} // Disable if fields are not filled
              >
                Start Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListProperty;
