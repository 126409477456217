import React from "react";
import PropertyReq from "../../Components/HomeComponent/PropertyReq";
import image from "../../Assets/FH_image.svg";
import { BASE_URL } from "../../Services/BaseUrl";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const LocalitiesList = ({ nearbyPropertiesData, loading }) => {
  const navigate = useNavigate();

  const encodeUri = (val) => {
    // Check if val is not null or undefined
    if (val && typeof val === "string") {
      // Check if the value starts with 'http'
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }

    // Handle the case where val is null or undefined
    console.warn(
      "Provided value is null or undefined, returning empty string."
    );
    return ""; // Or handle it as you see fit
  };

  const handleLocationClick = (locationsId) => {
    console.log("Navigating to property with ID:", locationsId);
    const params = new URLSearchParams({ locationid: locationsId });
    navigate(`/villas-list?${params.toString()}`);
  };

  console.log(nearbyPropertiesData, "nearby");

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator
  }

  if (!nearbyPropertiesData || !Array.isArray(nearbyPropertiesData)) {
    return <div>No properties available.</div>; // Fallback UI
  }




  const settings = {
    className: "center",
    dots: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 6,
    slidesToScroll: 1,
    // swipeToSlide: true,
    afterChange: function(index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
    responsive: [
      {
        breakpoint: 1440, // Between 1024px and 1440px
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      }, 
      {
        breakpoint: 1300, // Between 1024px and 1440px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },  
      {
        breakpoint: 1162, // Between 1024px and 1440px
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      }, 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      
    ]
  };
  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };
console.log(nearbyPropertiesData,'in local')
  return (
    <div className="NewProperty-container">
      <div className="component-title">
        <p className="newlyListed">Emerging localities</p>
        <p className="newProperty-title">
          Localities with recent development
        </p>
      </div>
      <div className='slider-container'>
      
      <Slider {...settings}>
        {nearbyPropertiesData.map((nearProperty) => (
          <PropertyReq
            key={nearProperty.locationsId} // Ensure property.id is unique
            image={decodeImgeString(nearProperty.propertyImage)} // Encode image URI if necessary
            title={nearProperty.locationName}
            price={nearProperty.propertyCount}
            onClick={() => handleLocationClick(nearProperty.locationsId)}
          />
        ))}
            </Slider>
        

      </div>
      {/* <div className="fh_image">
        <img src={image} alt="Featured Housing" />
      </div> */}
    </div>
  );
};

export default LocalitiesList;
