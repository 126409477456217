import React, { useEffect, useState } from 'react';
import VillasList from "../../Components/HomeComponent/Villas"; // Assuming the import is correct
import { BASE_URL } from "../../Services/BaseUrl";
import nofav from '../../Assets/No Fav.png';
import axios from "axios";
import '../../Styles/Favourites.css';
import { ToastContainer } from 'react-toastify';

const Favourites = () => {
  const userId = localStorage.getItem('userId');
  const [favourites, setFavourites] = useState([]);
  const token =localStorage.getItem('token');

  const encodeUri = (val) => {
    if (val && typeof val === 'string') {
      if (val.startsWith("http")) {
        return val;
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`;
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };

  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };

  const fetchFavourites = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Favourites/${userId}/flag`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setFavourites(response.data);
    } catch (error) {
      console.error("Error fetching favourites:", error);
    }
  };

  useEffect(() => {
    fetchFavourites();
  }, []);

  return (
    <div style={{ width: "100vw", padding: "15px 25px" }}>
      {favourites && favourites.length > 0 ? (
        <div className='favourites-container'>
         {favourites.map((villa) => (
  <VillasList
    key={villa.favouriteId}
    favouriteId={villa.favouriteId}
    propertyId={villa.propertyId}
    title={villa.propertyType}
    location={villa.locationName}
    area={villa.area}
    rooms={villa.noOfRooms}
    price={villa.propertyPrice}
    image={decodeImgeString(villa.images)}
    isInitiallyFavourite={true} // Update this according to your data structure
    fetchFavourites={fetchFavourites} // Pass fetchFavourites as a prop
  />
))}

        </div>
      ) : (
        <div className="no-favourites">
          <img src={nofav} alt="No Favourites" />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Favourites;
