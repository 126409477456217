import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../Components/HomeComponent/Header";
import "../../Styles/home.css";
import NewProperty from "./NewProperty";
import VillasList from "../../containers/Home/VillaList";
import LocalitiesList from "./LocalitiesList";
import { BASE_URL } from "../../Services/BaseUrl";
import Categories from "../Categories/Categories";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [data, setData] = useState([]);
  const [totalProperties, setTotalProperties] = useState("")
  const [totalUsers, setTotalUsers] = useState("")
  const [loading, setLoading] = useState(true);
  const [advertiseData, setAdvertiseData] = useState([]);
  const [comfortLiv, setComfortLiv] = useState([]);
  const [nearbyPropertiesData, setNearbyPropertiesData] = useState([]);
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const [locationName, setLocationName] = useState("");
  const navigate=useNavigate()

  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  // Chunking the data to group 3 items per slide
  const groupedPropertyTypes = chunkArray(nearbyPropertiesData, 5);
  const groupedComfortLiv = chunkArray(comfortLiv, 3);

  const encodeUri = (val) => {
    if (val && typeof val === 'string') {
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }
    
    console.warn("Provided value is null or undefined, returning empty string.");
    return ""; // Or handle it as you see fit
  };

 useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          localStorage.setItem("latitude", latitude);
          localStorage.setItem("longitude", longitude);
        },
        (error) => {
          console.error("Error fetching location:", error);
          navigate("/");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const fetchLocationName = async () => {
      if (latitude && longitude) {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          setLocationName(response.data.address.suburb || "Unknown Location");
        } catch (error) {
          console.error("Error fetching location name:", error);
        }
      }
    };

    fetchLocationName();
  }, [latitude, longitude]);



  const fetchData = async () => {
    console.log(typeof userId,'in home')
    try {
      const response = await axios.get(`${BASE_URL}/Dashboard`, {
        params: {
          userId: userId
        },
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        
      });
      setTotalProperties(response.data.totalProperties);
      setTotalUsers(response.data.totalUsers);
            console.log(response.data.totalProperties, "Fetched data");
      setData(response.data.propertyTypes);
      setAdvertiseData(response.data.advertisements || []); // Ensure it's an array
      setComfortLiv(response.data.apartments);
      setNearbyPropertiesData(response.data.nearbyProperties);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  // Log the state after it's been updated
  useEffect(() => {
    console.log("Data:", data);
    console.log("Advertisements:", advertiseData);
    console.log("Comfort Living:", comfortLiv);
    console.log("Nearby Properties:", nearbyPropertiesData);
  }, [data, advertiseData, comfortLiv, nearbyPropertiesData]); // Depend on all relevant state variables

  // Ensure we only access the advertisement images if they exist
  const firstAdImage = advertiseData.length > 0 ? encodeUri(advertiseData[0].imageUrl) : "";
  const secondAdImage = advertiseData.length > 1 ? encodeUri(advertiseData[1].imageUrl) : "";
// console.log(comfortLiv,'comliv')
  return (
    <div className="home-container" style={{display:"flex",flexDirection:"column",gap:"30px",position:"relative",zIndex:"1",marginTop:"-10%"}}>
      {/* <ToastContainer /> */}
      <Header totalProperties={totalProperties} totalUsers={totalUsers} />
      <div style={{display:"flex",flexDirection:"column",gap:"30px"}}>
      <Categories loading={loading}/>
      <NewProperty data={data} loading={loading} />
      {firstAdImage && (
        <div className="fh_image">
          <img src={firstAdImage} alt="Featured Housing" loading="lazy"  className="ads-image" />
        </div>
      )}
      <VillasList comfortLiv={comfortLiv} loading={loading} />
     
      <LocalitiesList nearbyPropertiesData={nearbyPropertiesData} loading={loading} groupedPropertyTypes={groupedPropertyTypes} />
      {secondAdImage && (
        <div className="fh_image">
          <img src={secondAdImage} loading="lazy" alt="Featured Housing" />
        </div>
      )}
    </div>
    <ToastContainer />
    </div>
  );
}
