import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";
import logoimg from "../../Assets/firsthouse.svg";
import "../../Styles/Login.css";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpData, setOtpData] = useState("");
  const [errorBorder, setErrorBorder] = useState(false);  // State for error border
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleChange = (value, index) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 3) {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && code[index] === "") {
      if (index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      }
    }
  };

  const handleVerificationClick = async () => {
    if (phoneNumber.length < 10) {
      setError("Please enter a valid phone number.");
      return;
    }
    setError(""); // Clear previous errors
    try {
      const response = await axios.post(
        `${BASE_URL}/Login/${phoneNumber}/userLogin?notificationToken=ertyuio`
      );
      setOtpData(response.data);
      setOtpSent(true);
      setCode(["", "", "", ""]); // Clear the code input fields
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("Failed to send OTP. Please try again.");
    }
  };

  const handleOtpSubmit = async () => {
    const otpEntered = code.join("");
    if (otpEntered === (otpData.otp).toString()) {
      const userId = otpData?.userdata?.userId;
      const token = otpData.token;
      const userName = otpData?.userdata?.username;
      const mobileNumber = otpData?.userdata?.mobileNumber;
      const profileImage = otpData?.userdata?.profileImage;
      localStorage.setItem("profileImage", profileImage);
      localStorage.setItem("userId", userId);
      localStorage.setItem("token", token);
      localStorage.setItem("username", userName);
      localStorage.setItem("mobileNumber", mobileNumber);

      // Request location access
      navigate("/home");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            localStorage.setItem("latitude", latitude);
            localStorage.setItem("longitude", longitude);
          
          },
          (error) => {
            console.error("Error fetching location:", error);
            navigate("/home"); 
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        navigate("/home");
      }
    } else {
      setError("Invalid OTP. Please try again.");
      setCode(["", "", "", ""]); 
      setErrorBorder(true); 
    }
  };

  const handleEditPhoneNumber = () => {
    setOtpSent(false);
    setPhoneNumber("");
  };

  return (
    <div className="lp-background">
      <div className="lp-overlay">
        <div className="lp-logo-container">
          <img src={logoimg} loading="lazy" alt="First House Logo" className="lp-logo" />
          <h1 className="lp-company-name">First House</h1>
        </div>

        {!otpSent ? (
          <div className="lp-verification-box">
            <h2 className="lp-h2">Enter Phone number for verification</h2>
            <div className="lp-p">
              We’ll text a code to verify your phone number
            </div>
            <div className="lp-input-container">
              <div className="lp-country-select">
                <span className="lp-span">+91</span>
              </div>
              <input
                type="tel"
                className="lp-phone-input"
                placeholder="00000 00000"
                value={phoneNumber}
                onChange={handleInputChange}
                maxLength={10}
                required
              />
            </div>
            {error && <div className="lp-error-message">{error}</div>}
            <button
              className="lp-verification-button"
              onClick={handleVerificationClick}
            >
              Get Verification Code
            </button>
          </div>
        ) : (
          <div className="vp-verification-container">
            <h2 className="vp-h2">Verify Phone Number</h2>
            <p className="vp-p">
              Please enter the 4 digit code sent to +91 {phoneNumber}
            </p>
            <span
              className="vp-edit-phone"
              onClick={handleEditPhoneNumber}
            >
              Edit your phone number?
            </span>
            <div className="vp-input-group">
              {code.map((digit, index) => (
                <input
                  key={index}
                  id={`input-${index}`}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  className={`vp-input-box ${errorBorder ? "error-border" : ""}`}
                />
              ))}
            </div>
            {error && <div className="lp-error-message">{error}</div>}
            <button
              className="lp-verification-button"
              onClick={handleOtpSubmit}
            >
              Verify OTP
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
