import React, { useState } from 'react';
import { FaPaperPlane, FaPaperclip, FaSmile } from 'react-icons/fa';
import '../../Styles/v-Chat-popup.css';

const Chat = ({ closeChat }) => {
  const [messages, setMessages] = useState([
    { id: 1, sender: 'Abhimanyu S', time: '21 min ago', text: 'Hey, fellas! I think we can share part of our Design system with the Dribble community. What do you think?' },
    { id: 2, sender: 'Jenny Wilson', time: '15 min ago', text: 'Hi! I think this is a brilliant idea 🤔 Do you have any specific components you want to share?' },
  ]);
  const [newMessage, setNewMessage] = useState('');

  const sendMessage = () => {
    if (newMessage.trim()) {
      setMessages([...messages, { id: messages.length + 1, sender: 'John', time: 'Just now', text: newMessage }]);
      setNewMessage(''); // Clear input field after sending
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h3>Chat with Abhimanya Legal Services</h3>
        <button className="close-chat" onClick={closeChat}>×</button>
      </div>

      <div className="chat-body">
        {messages.map((message) => (
          <div key={message.id} className="message">
            <div className="message-info">
              <strong>{message.sender}</strong> <span>{message.time}</span>
            </div>
            <p className="message-text">{message.text}</p>
          </div>
        ))}
        <div className="typing-indicator">
          <span>• • • John is typing</span>
        </div>
      </div>

      <div className="chat-footer">
        <input
          type="text"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <div className="chat-actions">
          <button className="attach-button">
            <FaPaperclip />
          </button>
          <button className="emoji-button">
            <FaSmile />
          </button>
          <button className="send-button" onClick={sendMessage}>
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
